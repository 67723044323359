import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Stats from "./Stats";

function StatsSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <Stats
          items={[
            {
              title: "COMPETITORS",
              stat: "3,456",
            },
            {
              title: "FITRACES",
              stat: "642",
            },
            {
              title: "AT STAKE",
              stat: "$22,465",
            },
            {
              title: "CALORIES BURNED",
              stat: "305,435,556",
            },
          ]}
        ></Stats>
      </Container>
    </Section>
  );
}

export default StatsSection;
