import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Testimonials from "./Testimonials";

function TestimonialsSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Testimonials
          items={[
            {
              avatar: "https://uploads.divjoy.com/pravatar-150x-5.jpeg",
              name: "Sarah Kline",
              testimonial: `I was a XC runner in highschool, and I didn't realize how much I missed the competition. Suddenly I was running 10 and 15 miles more a week. I couldn't sleep if I was behind in my FitRace.`,
              company: "FitRace Runner",
            },
            {
              avatar: "https://uploads.divjoy.com/pravatar-150x-48.jpeg",
              name: "Shawna Murray",
              testimonial: `I've tried social accountability apps before, but my friends didn't hold me to it and it wasn't fun. I lost my first competition, but I also lost four pounds and I was hooked.`,
              company: "FitRace Weight Loss",
            },
            {
              avatar: "https://uploads.divjoy.com/pravatar-150x-12.jpeg",
              name: "Blake Elder",
              testimonial: `I wanted to get into Crossfit competitions but I didn't have the time. FitRace puts my progress and training in perspective by putting me up against people at my level.`,
              company: "Crossfit",
            },
          ]}
        ></Testimonials>
      </Container>
    </Section>
  );
}

export default TestimonialsSection;
