import React from "react";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import CtaSection from "./../components/CtaSection";
import { useRouter } from "./../util/router.js";

function AboutPage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection2
        bg="white"
        textColor="dark"
        size="lg"
        bgImage=""
        bgImageOpacity={1}
        title="FitRace is about the joy of competition"
        subtitle="FitRace was created by people who love the feeling of competing neck and neck with a close competitor with something on the line. The goal of FitRace is to help people have more fun achieving greater health and wellness by leveraging the powerful combination of social, financial, and competitive incentives within the context of a supportive global community."
      ></HeroSection2>
      <StatsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
      ></StatsSection>
      <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="light"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/pricing");
        }}
      ></CtaSection>
    </>
  );
}

export default AboutPage;
