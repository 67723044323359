import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import TeamBios from "./TeamBios";

function TeamBiosSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <TeamBios
          items={[
            {
              headerImage: "https://source.unsplash.com/pe_R74hldW4/1080x360",
              avatarImage: "https://uploads.divjoy.com/pravatar-150x-68.jpeg",
              name: "John Smith",
              role: "FitRace Runner",
              bio: `$20 race entry fee, FitRace for greatest total miles run in 1 month, winner takes $28. John's goal is to run 40 miles in the next month. John has won two FitRaces and lost one FitRace. John has been running an average of 25 miles a month, with an average five mile time of 40 minutes.`,
              twitterUrl: "https://twitter.com",
              facebookUrl: "https://facebook.com",
              linkedinUrl: "https://linkedin.com",
            },
            {
              headerImage: "https://source.unsplash.com/uwPBAlQyIag/1080x360",
              avatarImage: "https://uploads.divjoy.com/pravatar-150x-35.jpeg",
              name: "Lisa Zinn",
              role: "FitRace Weight Loss",
              bio: `$15 race entry fee, FitRace for most weight lost in 1 month, winner takes $21. Lisa's goal is to lose 5 pounds in the next month. Lisa has won two races and lost one race. Lisa lost 3 pounds in the last month, with a net daily caloric intake of -300.`,
              twitterUrl: "https://twitter.com",
              facebookUrl: "https://facebook.com",
              linkedinUrl: "https://linkedin.com",
            },
            {
              headerImage: "https://source.unsplash.com/pJ_DCj9KswI/1080x360",
              avatarImage: "https://uploads.divjoy.com/pravatar-150x-16.jpeg",
              name: "Diana Low",
              role: "FitRace CrossFit",
              bio: `$1 race entry fee, FitRace for greatest max deadlift percent gain, winner takes $0.70. Diana's goal is to add 15% to best most recent 250 pound max deadlift. Diana has won five races and never lost a race. Lisa added 20% to her max deadlift in the last month, with a net weekly gain of 5%.`,
              twitterUrl: "https://twitter.com",
              facebookUrl: "https://facebook.com",
              linkedinUrl: "https://linkedin.com",
            },
          ]}
        ></TeamBios>
      </Container>
    </Section>
  );
}

export default TeamBiosSection;
