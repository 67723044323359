import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Faq from "./Faq";

function FaqSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Faq
          items={[
            {
              question: "What is FitRace?",
              answer:
                "FitRace is a new way to participate in amateur competitions, and one of the best opportunities to measure your training against individuals of similar fitness levels. FitRace entry fees are as little as $1, 5% of each entry fee goes to each of two judges who evaluate the race, 20% goes to the house, and 70% goes to the winner as determined by the judge verified data provided by each competitor.",
            },
            {
              question: "Who is FitRace for?",
              answer:
                "FitRace is for anyone who enjoys friendly competition, or would benefit from the motivation provided by financial, social, and competitive incentive to help reach their health and fitness goals. FitRace is for busy working professionals and serious athletes of all fitness levels.",
            },
          ]}
        ></Faq>
      </Container>
    </Section>
  );
}

export default FaqSection;
