import React from "react";
import CtaSection2 from "./../components/CtaSection2";
import TeamBiosSection from "./../components/TeamBiosSection";
import ContactSection from "./../components/ContactSection";
import { useRouter } from "./../util/router.js";
import { requireAuth } from "./../util/auth.js";

function DashboardPage(props) {
  const router = useRouter();

  return (
    <>
      <CtaSection2
        bg="dark"
        textColor="light"
        size="sm"
        bgImage="https://fitrace.s3.amazonaws.com/CTARunningWoman.png"
        bgImageOpacity={0.8}
        title="1. Complete Profile Information"
        subtitle=""
        buttonText="Enter Information"
        buttonColor="light"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/settings/:section");
        }}
      ></CtaSection2>
      <br></br>
      <CtaSection2
        bg="dark"
        textColor="light"
        size="sm"
        bgImage="https://fitrace.s3.amazonaws.com/RunningLegs.png"
        bgImageOpacity={0.8}
        title="2. Credit FitRace Account"
        subtitle=""
        buttonText="Get Entry Fees"
        buttonColor="light"
        buttonOnClick={() => {
          // Navigate to pricing page
          router.push("/pricing");
        }}
      ></CtaSection2>
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Start Your Race"
        subtitle=""
      ></TeamBiosSection>
      <ContactSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Request an above FitRace or propose your own race and we'll email you when your FitRace is ready!"
        subtitle=""
        buttonText="Submit"
        buttonColor="primary"
        showNameField={true}
        inputSize="md"
      ></ContactSection>
    </>
  );
}

export default requireAuth(DashboardPage);
