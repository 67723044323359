import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Features2 from "./Features2";

function FeaturesSection2(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        ></SectionHeader>
        <Features2
          items={[
            {
              title: "1. Fitness Goals & Level",
              description:
                "Losing weight or lifting it, FitRace matches you with competitors and competitions based on your goals and fitness baseline.",
              image: "https://uploads.divjoy.com/undraw-mind_map_cwng.svg",
            },
            {
              title: "2. Pick Judges & Stakes",
              description:
                "Each competitor picks a judge, and a puts up half of the entry fee which becomes the stakes of the FitRace.",
              image:
                "https://uploads.divjoy.com/undraw-personal_settings_kihd.svg",
            },
            {
              title: "3. Start Your Competition",
              description:
                "Upload the health and fitness metrics and video and image verification as required by your judges.",
              image: "https://uploads.divjoy.com/undraw-having_fun_iais.svg",
            },
            {
              title: "4. Win Your FitRace!",
              description:
                "Continuously track where you stand against your competitition, and don't forget to support one another!",
              image: "https://uploads.divjoy.com/undraw-balloons_vxx5.svg",
            },
          ]}
        ></Features2>
      </Container>
    </Section>
  );
}

export default FeaturesSection2;
